import { Container } from "./styles";

export function Footer() {
  return (
    <Container className="footer">
      <a href="http://www.carlosfurtado.com.br/" className="logo">
        <span>C</span>
        <span>Furtado</span>
      </a>
      <div className="social-media">
        <p>Copyright &copy; {new Date().getFullYear()}</p>
      </div>
    </Container>
  );
}
