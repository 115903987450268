import { Contact } from "../Contact/Contact";
import { Hero } from "../Hero/Hero";
import { Container } from "./styles";

export function Main() {
  return (
    <Container>
      <Hero></Hero>
      {/* <About></About> */}
      <Contact></Contact>
    </Container>
  );
}
