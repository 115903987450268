import emailIcon from "../../assets/email-icon.svg";
import { Container } from "./styles";

export function Contact() {
  return (
    <Container id="contato">
      <header>
        <h2>Entre em contato</h2>
      </header>
      <div className="contacts">
        <div>
          <img src={emailIcon} alt="Email" />
          <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            {process.env.REACT_APP_EMAIL}
          </a>
        </div>
      </div>
    </Container>
  );
}
