import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "./styles";

export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>Olá 👋, bem vindo ao</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>{process.env.REACT_APP_NAME}</h1>
        </ScrollAnimation>
        {/* <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Facilitando seu dia, todo dia.</h3>
        </ScrollAnimation> */}
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Em construção...</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume"></p>
        </ScrollAnimation>

        {/* <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <a href="https://app.gerenciamentopessoal.com.br/" className="button">
            <span>Acessar</span>
          </a>
        </ScrollAnimation> */}
      </div>
      {/* <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={Illustration} alt="Ilustração" />
        </ScrollAnimation>
      </div> */}
    </Container>
  );
}
